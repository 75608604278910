import { db } from '@/firebase/config'
import removeWish from '@/composables/WishList/removeWish'
import createNewWish from '@/composables/WishList/createNewWish'
import reactivateWish from '@/composables/WishList/reactivateWish';

import firebase from "firebase/app";
import "firebase/firestore";

const now = new firebase.firestore.Timestamp.now().toDate();

const editWish = async (isUpdateResumeOnly, companyId, companyName, wishId, candidateId, candidateCount, editedWishInfo, newWishIdStore, isDefaultResume) => {
  try {
    let promises = [];
    // let docRef = ;
    if (isUpdateResumeOnly){
      await db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId).collection("Candidates").where("uid","==",candidateId).get().then(docs => {
        docs.forEach(doc => {
          promises.push(doc.ref.update({
            isDefaultResume: isDefaultResume,
          }));
        });
      });
      await Promise.all(promises);
      await reactivateWish(companyId, wishId, candidateId);
    } else {
      if (candidateCount <= 1){
        await removeWish(companyId, wishId, candidateId, candidateCount, true);
      } else {
        await removeWish(companyId, wishId, candidateId, candidateCount, false);
      }
      await createNewWish(companyId, companyName, candidateId, editedWishInfo, newWishIdStore, isDefaultResume);
    }
		
    // console.log("editWish activated");
  } catch (error) {
    alert("editWish:" + error);
  }

};

export default editWish
