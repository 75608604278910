import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

const addCandidateToWish = async (companyId, wishId, candidateCount, candidateId, candidateInitials, isDefaultResume) => {
  try {
    const now = new firebase.firestore.Timestamp.now().toDate();
    let docRef = db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId);
		// console.log(companyId, wishId);
    await docRef.update({
			// candidateCount: candidateCount+1,
			lastCandidate: candidateInitials,
      lastActivityTime: now
		});
		
		await docRef.collection("Candidates").add({
        status: "pending",
        uid: candidateId,
        isDefaultResume: isDefaultResume,
        time: now
    });

    // console.log("addCandidateToWish activated");
  } catch (error) {
    alert("addCandidateToWish:" + error);
  }

};

export default addCandidateToWish
