import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

//candidateId: user.value.uid
//newWish: {}
const createNewWish = async (companyId, companyName, candidateId, newWish, wishIdStore, isDefaultResume) => {
  try {
    const now = new firebase.firestore.Timestamp.now().toDate();

    await db.collection("Companies").doc(companyId).collection("Wishes").add({
      company: companyName,
      position: newWish.position,
      link: newWish.link,
      city: newWish.city,
      state: newWish.state,
      isGranted: false,
      candidateCount: 0,
      lastCandidate: newWish.candidateInitials,
      lastActivityTime: now
    }).then(async docRef => {
      await docRef.collection("Candidates").add({
        status: "pending",
        uid: candidateId,
        isDefaultResume: isDefaultResume,
        time: now
      })
      wishIdStore.value = docRef.id;
    })
    // console.log("createNewWish activated");
  } catch (error) {
    alert("createNewWish:" + error);
  }

};

export default createNewWish
