import { db } from '@/firebase/config'
import { ref } from "vue";
import getProfile from '@/composables/Profile/getProfile'
import firebase from "firebase/app";
import "firebase/firestore";


const removeWish = async (companyId, wishId, candidateId, candidateCount, isDeleteWhole) => {
  try {
		let promises = [];
		let docRef = db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId);
		// if (isDeleteWhole){
		// 	await docRef.collection("Candidates").get().then(docs => {
		// 		docs.forEach(doc => {
		// 			promises.push(doc.ref.delete());
		// 		})
		// 	})
		// 	await Promise.all(promises);
		// 	await docRef.delete();
		// } else {
		const now = new firebase.firestore.Timestamp.now().toDate();
		let replaceInitials="ERROR!";
		const profile = ref(null);
		// delete candidate within Wish
		await docRef.collection("Candidates").where("uid","==",candidateId).get().then(docs => {
			promises = [];
			docs.forEach(doc => {
				promises.push(doc.ref.delete());
			})
		})
		await Promise.all(promises);
		// getting a random candidate's name initials
		await docRef.collection("Candidates").get().then(async snapshot => {
			let tempUid = null;
			snapshot.forEach(doc => {
				if (!tempUid){
					tempUid = doc.data().uid;
				}
			});
			if (tempUid) {
				await getProfile(profile, tempUid);
				replaceInitials = profile.value.firstName.slice(0,1).toUpperCase() + ". " + profile.value.lastName.slice(0,1).toUpperCase() + ". ";
				await docRef.update({
					lastCandidate: replaceInitials,
					lastActivityTime: now
				})
			}
		})
		// update Wish data
		
		//}
    // console.log("removeWish activated");
  } catch (error) {
    alert("removeWish:" + error);
  }

};

export default removeWish
