import { db } from '@/firebase/config';
import firebase from "firebase/app";
import "firebase/firestore";

const now = new firebase.firestore.Timestamp.now().toDate();

const reactivateWish = async (companyId, wishId, candidateId) => {
  try {
    let docRef = db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId);
    let promises = [];
    
    await docRef.update({
			lastActivityTime: now
    });

    await docRef.collection("Candidates").where("uid","==",candidateId).get().then(docs => {
      docs.forEach(doc => {
        promises.push(doc.ref.update({
          time: now
        }));
      })
      return Promise.all(promises);
    })
    // console.log("candidate update successful")

    promises = [];
    await db.collection("Profiles").doc(candidateId).collection("myWishes").where("wishId","==",wishId).get().then(docs => {
      docs.forEach(doc => {
        promises.push(doc.ref.update({
          time: now
        }));
      })
      return Promise.all(promises);
    })
    // console.log("all update successful")

    // console.log("reactivateWish activated");
  } catch (error) {
    alert("reactivateWish:" + error);
  }
};

export default reactivateWish
